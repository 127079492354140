body{
  font-family: 'Open Sans', sans-serif !important;
}

:root{
  --blue : #205178;
  --orange : #EE6F2D;
}


a {
  color: #1f5178;
  text-decoration: none;
  font-weight: 500;
}

// Menu
nav.navbar  {
  background-color: var(--blue);
  a.nav-link{
    color: #fff !important;
  }
  a.nav-link:hover{
    color:var(--orange) !important;
  }
  .navbar-nav{
    button.btn.btn-primary {
      background-image: url(../images/chevron-down-white.svg);
      background-repeat: no-repeat;
      background-size: 14px;
      background-color: #275980;
      border: none;
      background-position: 90% 51%;
      padding: 4px 33px 7px 18px;
      border-radius: 23px;
      margin-left: 10px;
    }
  }
}



// Dashboard
.dashboard{
  a.badge {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
    background-color: var(--blue);
    border-radius: 50px;
    margin: 5px;
    padding: 12px 25px;
    width: 210px;
    transition: .3s;
  }
  a.badge:hover{
    background-color:var(--orange) !important;
  }
}




.page-btn-title {
  button.btn.btn-success{
    background-color:var(--blue);
    border-color:var(--blue);
  }
}




.category-controller {
  margin-top: 50px;
}

/* TEXT EDITOR CSS */
.editorClassName.rdw-editor-main {
  border: 1px solid;
}
/* MODAL SHOW */
.offer-modal-show{
  text-align: center !important;
  color: #000000ab !important;
  font-size: 15px !important;
  margin-top: 25px !important;

}

/* DELETE FUNCTION MODAL */
.modal-body {
  text-align: center;
  color: #ff0000ab;
  font-size: 59px;
  margin-top: 25px;

}
h6.removeHeader {
  color: #000000d1;
  font-weight: 600;
}
.deleteButton {
  margin: 10px;
}

.margin {
  margin-top: 20px;
}


// Pagination
.page-item.active .page-link {
  background-color: var(--blue);
  border-color: var(--blue);
}

/* CARD BODY */
.version-controller{
  margin-top: 50px;
  background-color: rgb(231, 230, 230);
  padding: 30px;
  .btn-danger {
    background-color: #636363 ;
    border-color: #636363 ;
  }
  .btn-danger:hover {
    background-color: #858585 ;
    border-color: #858585 ;
  }
  .btn.btn-success,
  .btn.btn-primary{
    background-color: var(--blue);
    border-color: var(--blue);
  }
  .btn.btn-success:hover,
  .btn.btn-primary:hover{
    background-color: #3b6d94;
    border-color: #3b6d94;
  }
  span.label-note {
    font-size: 13px;
    font-weight: 600;
  }
}

/* TEXT EDITOR  */
.ql-container.ql-snow {
  background-color: white;
}

/* IMAGE UPLODER */
img.image-logo {
  width: 150px;
}
.react-fa{
  font-size: 20px;
  margin-left: 10px;
  color: red;
  margin-bottom: 130px;
}

/* NavBar */


/* LOGIN PAGE */
.before-login {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9e9e9;
}



.login-form {
  background-color: transparent;
  margin: auto;
  max-width: 100%;
  border-radius: 15px;
  border: 1px solid #D6A52E;
  width: 500px;
  background-color: var(--blue);
  padding: 50px;
  border: none;
}





.gallery{
  padding: 15px;
  margin-bottom: 15px;
  .image-block{
    position: relative;
    margin-bottom: 25px;
    img.image-logo {
      width: 160px;
    }
    .react-fa {
      font-size: 20px;
      margin-left: 10px;
      color: red;
      margin-bottom: 0;
      position: absolute;
      right: 21%;
      top: -8px;
      cursor: pointer;
    }
  }
}


// OFFERS POP UP MODAL
.offer-poup-section{
  background-color: #ffffff;
  padding: 10px;
}


// REPORTS SECTION
.reports-component {
  .date-picker {
    background-image: url(../images/calendar.svg);
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: 93% 50%;
     
  }
  .table-responsive {
    padding-top: 15px;
  }
}

// OFFERS SECTION
.offers-component {
  .date-picker {
    background-image: url(../images/calendar.svg);
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: 93% 50%;
  }
  .table-responsive {
    padding-top: 15px;
  }
}

 