body {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;

}

.title {
    color: rgb(0, 0, 0);
}
.login-container {
    height: 100vh;
    width: 100%;
}

.login-form .form-control {
    font-size: 15px;
    min-height: 48px;
    font-weight: 500;
    border-radius: 0 !important;
}

.login-form a:hover {
    color: #723dbe;
}
.forgot-link {
    font-size: 13px;
}

.form-control:focus {
    border-color: #000000;
    box-shadow: none;
}
.btn-custom {
    background: #000000 !important;
    border-color: #000000 !important;
    color: #fff !important;
    font-size: 15px;
    font-weight: 600;
    min-height: 48px;
    border-radius: 0 !important;

}

.btn-custom:focus,
.btn-custom:hover,
.btn-custom:active,
.btn-custom:active:focus {
    background: #54229d;
    border-color: #54229d;
    color: #fff;
}
.btn-custom:focus {
    box-shadow: 0 0 0 0.2rem rgba(114, 61, 190, 0.25);
}

 
